import type { RouteRecordRaw } from 'vue-router';

const contentRouters: RouteRecordRaw[] = [
  {
    path: 'contents',
    name: '',
    redirect: {
      name: 'ProductContent',
    },
    component: () => import('../dashboard/layouts/Index.vue'),
    children: [
      {
        path: '',
        name: 'Contents',
        component: () => import('./Contents.vue'),
        children: [
          {
            path: 'product:?',
            name: 'ProductContent',
            component: () => import('./pages/ProductContent.vue'),
          },
          {
            path: 'collection',
            name: 'CollectionContent',
            component: () => import('./pages/CollectionContent.vue'),
          },
          {
            path: 'blog',
            name: 'BlogContent',
            component: () => import('./pages/BlogContent.vue'),
          },
          {
            path: 'article',
            name: 'ArticleContent',
            component: () => import('./pages/ArticleContent.vue'),
          },
        ],
      },
    ],
  },
];

export default contentRouters;
