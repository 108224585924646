import type { RouteRecordRaw } from 'vue-router';

const settingRouters: RouteRecordRaw[] = [
  {
    path: 'settings',
    name: 'Settings',
    redirect: {
      name: 'Domain',
    },
    component: () => import('../dashboard/layouts/Index.vue'),
    children: [
      {
        path: '',
        name: 'Settings',
        component: () => import('./Setting.vue'),
        children: [
          // {
          //   path: 'domain',
          //   name: 'Domain',
          //   component: () => import('./layouts/Domain.vue'),
          // },
          // {
          //   path: 'seo',
          //   name: 'Seo',
          //   component: () => import('./layouts/Seo.vue'),
          // },
        ],
      },
    ],
  },
];

export default settingRouters;
