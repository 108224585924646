import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import instantRoutes from './modules/instant/routes';
import HyperlinkLayout from './modules/hyperlink/layouts/HyperlinkLayout.vue';

const routeMap = import.meta.glob<true, string, { default: RouteRecordRaw[] }>('./modules/*/routes.ts', {
  eager: true,
});

const storefrontRoutes = Object.values(routeMap)
  .map((data) => data.default)
  .flat()
  .filter((item) => {
    if (
      !item.name?.toString().includes('Instant') &&
      !instantRoutes.find((route) => route.name === item.name?.toString())
    )
      return item;
  });

const instantWelcomeRouters = Object.values(routeMap)
  .map((data) => data.default)
  .flat()
  .filter((item) => {
    if (item.name?.toString().includes('Instant') && item.name?.toString() === 'InstantWelcome') return item;
  });

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    children: [
      // { // Disable storefront
      //   path: '',
      //   redirect: {
      //     name: 'Dashboard',
      //     params: {
      //       shopHandle: 'unknown',
      //     },
      //   },
      // },
      {
        path: '',
        redirect: {
          name: 'InstantPages',
        },
      },
      {
        path: 'instant/welcome',
        component: () => import('@/layouts/Welcome.vue'),
        children: instantWelcomeRouters,
      },
      {
        path: 'instant',
        name: 'Instant',
        component: () => import('@/layouts/Index.vue'),
        children: instantRoutes,
      },
      {
        path: 'instant/hyperlink',
        name: 'InstantHyperlink',
        component: HyperlinkLayout,
        children: [
          {
            path: 'edit',
            name: 'InstantHyperlinkEdit',
            component: () => import('@/modules/hyperlink/pages/edit-page.vue'),
          },
          {
            path: 'new',
            name: 'InstantHyperlinkNew',
            component: () => import('@/modules/hyperlink/pages/new-page.vue'),
          },
        ],
      },
      {
        path: ':shopHandle/',
        component: () => import('@/layouts/Index.vue'),
        children: storefrontRoutes,
      },
      {
        path: ':shopHandle/:pathMatch(.*)',
        component: () => import('@/PathNotFound.vue'),
      },
    ],
  },
  {
    path: '/preview',
    component: () => import('@/modules/preview/pages/PreviewPage.vue'),
  },
  {
    path: '/icons',
    component: () => import('@/modules/preview/pages/Icons.vue'),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Handle check token in localStorage
router.beforeEach((to, from, next) => {
  next();
});

export default router;
