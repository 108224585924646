<script lang="ts" setup>
import useCurrentShop from '@/composables/useCurrentShop';
import { useShopAuth } from '@/composables/useShopAuth';

useShopAuth();

// connect shop success
const currentShop = useCurrentShop();
</script>

<template>
  <router-view v-if="currentShop"></router-view>
</template>
