import type { ConnectedType } from '@/types/connect';
import { useRoute } from 'vue-router';
import { useShopQuery } from './useShopQuery';

export const useStoreFrontAuthentication = (
  connectedType: ComputedRef<ConnectedType>,
  editorType: ComputedRef<string>,
) => {
  const route = useRoute();

  const shopHandle = computed(() => {
    if (isEnableStoreFrontAuth()) {
      return route.params.shopHandle?.toString();
    }
    return;
  });

  const isEnableStoreFrontAuth = () => {
    return (
      (connectedType.value === 'editor' && editorType.value === 'storefront') || connectedType.value === 'storefront'
    );
  };

  useShopQuery({
    shopHandle: shopHandle.value,
  });
};
