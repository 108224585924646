import type { App } from 'vue';

export type EmitType = 'open' | 'close' | 'message' | 'error';
export type EmitFunc = (name: EmitType, data?: any) => void;

export type EmitMessageFunc = (type: string, data?: any) => void;

export type WebsocketOptions = {
  reconnectEnabled?: boolean;
  reconnectInterval?: number;
  keys?: string[];
};

export interface WebsocketPlugin {
  install: (app: App, options: WebsocketOptions, shopId?: string) => void;
}

export const _events: Record<string, any[]> = {};
export const _messageEvents: Record<string, any[]> = {};

export type WebsocketStore = {
  sendMessage: (message: string | ArrayBufferLike | Blob | ArrayBufferView) => void;
  sendObject: (data: Record<string, any>) => void;
  reconnect: (shopId: string) => void;
  connect: (shopId: string) => void;
  disconnect: () => void;
  on: (name: EmitType, cb: (ev: MessageEvent) => void) => any;
  removeListener: (key: EmitType, cb: (ev: MessageEvent) => void) => void;
  onMessage: <T>(type: string, cb: (data: T) => void) => any;
  offMessage: (type: string, cb: (ev: MessageEvent) => void) => void;
  removeMessageListener: (key: string, cb: (ev: MessageEvent) => void) => void;
};
