import type { RouteRecordRaw } from 'vue-router';

const instantWelcomeRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'InstantWelcome',
    component: () => import('./pages/Welcome.vue'),
  },
];

export default instantWelcomeRoutes;
