import type { ShopLoginResponse } from '@/api/instant/queries/useInstantShopLoginQuery';
import useInstantShopLoginQuery from '@/api/instant/queries/useInstantShopLoginQuery';
import useShopStore from '@/stores/shop';
import type { ConnectedType } from '@/types/connect';
import { useRoute, useRouter } from 'vue-router';
import useNotification from './useNotification';
import { useShopQuery } from './useShopQuery';

export const useInstantAuthentication = (
  connectedType: ComputedRef<ConnectedType>,
  editorType: ComputedRef<string>,
) => {
  const route = useRoute();
  const router = useRouter();
  const shopStore = useShopStore();
  const { handleError } = useNotification();

  const isEnableInstantAuth = computed(
    () => (connectedType.value === 'editor' && editorType.value === 'instant') || connectedType.value === 'instant',
  );

  const shopID = computed(() => shopStore.getShopId);

  useInstantShopLoginQuery(
    {
      connectedType: 'gempagesv7',
      key: 'check-gp-v5-use-instant',
    },
    {
      enabled: isEnableInstantAuth,
      onSuccess: (v: ShopLoginResponse) => {
        shopStore.setIsGemPagesV7(v.isGemPagesV7);
      },
    },
  );

  const { data: dataShopLogin, refetch } = useInstantShopLoginQuery(
    {
      connectedType: 'instant',
    },
    {
      enabled: isEnableInstantAuth,
      onError: handleError,
      onSuccess: (v) => {
        handleSuccess(v);
      },
    },
  );

  const handleSuccess = (v: ShopLoginResponse) => {
    setShopInfoInStore(v);
    checkRedirectToWelcome(v);
  };

  const setShopInfoInStore = (v: ShopLoginResponse) => {
    if (!shopStore.getIsAdminLogin) {
      shopStore.setShopId(v?.shopID);
      shopStore.setToken(v?.token);
      shopStore.setShopDomain(v?.shopifyDomain);
    }
    shopStore.setUserHash(v?.userHash);
    shopStore.setFirstName(v?.firstName);
    shopStore.setIsGemPagesV7(v.isGemPagesV7);
    const isConnected = v?.shopID && v?.shopID !== '0' && v?.status == 'CONNECTED';
    shopStore.setStatus(!!isConnected);
    shopStore.setShopType(v.isGemPagesV7);
  };

  const checkRedirectToWelcome = (v: ShopLoginResponse) => {
    if (v.shopID === '0' || !v.shopID) {
      shopStore.setShop(undefined);
      router.replace({
        name: 'InstantWelcome',
        query: route.query,
      });
    }
  };

  useShopQuery({
    shopID,
  });

  watch(
    () => route.name,
    () => {
      if (route.name === 'InstantPages' && dataShopLogin.value) {
        refetch.value();
      }
    },
  );
};
