import type { App, Component } from 'vue';
import { createApp, h } from 'vue';
import './styles/index.css';
import './styles/placeholder.scss';
import './styles/common.scss';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'v-calendar/style.css';

import AppInit from './App.vue';
import routes from './routes';
import Notifications from 'notiwind';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import websocketPlugin from '@gem/websocket';
import { createPinia } from 'pinia';
import { setupCalendar, DatePicker } from 'v-calendar';
import { initUIKit } from '@gem/uikit';
import type { VueQueryPluginOptions } from 'vue-query';
import { VueQueryPlugin } from 'vue-query';
import * as Sentry from '@sentry/vue';
import VueClickAway from 'vue3-click-away';
import { createGtm } from '@gtm-support/vue-gtm';
import { getConfig } from './config';
import { clearLoginAs } from '@/api/helpers';
import Vue3Lottie from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';
import { WEB_SOCKET_KEY, WEB_SOCKET_KEY_V5 } from '@gem/websocket/const';
// PWA
// import { useRegisterSW } from 'virtual:pwa-register/vue';

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: 3,
        onError: (err: any) => {
          const errors = err?.response?.errors;
          const isUnAuthorized = errors?.some((error: any) => error?.extensions?.response?.status === 401);
          if (isUnAuthorized) {
            clearLoginAs();
            const redirectUri = window.location.href;
            const apiUrl = new URL(getConfig('url').account);
            window.location.replace(`https://${apiUrl.hostname}/login?redirect_uri=${redirectUri}`);
          } else {
            Sentry.withScope((scope) => {
              scope.setLevel('log');
              scope.setTag('kind', 'query');
              scope.setExtra('data', JSON.stringify(err));
            });
          }
        },
      },
      mutations: {
        onError(error, variables, context) {
          Sentry.withScope((scope) => {
            scope.setLevel('log');
            scope.setTag('kind', 'mutation');
            scope.setExtra('data', JSON.stringify({ error, variables, context }));
          });
        },
      },
    },
  },
};

const vueApp = createApp({
  render: () => h(AppInit),
});

const SentryInit = (app: App<Element>) => {
  Sentry.init({
    app,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.release,
    dsn: getConfig('featureConfig').sentryCDN,
    integrations: [
      new Sentry.BrowserTracing({
        startTransactionOnLocationChange: true,
        // shouldCreateSpanForRequest: true,
        traceFetch: true,
        traceXHR: true,
        routingInstrumentation: Sentry.vueRouterInstrumentation(routes),
        shouldCreateSpanForRequest: () => {
          return true;
        },
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: getConfig('configEnv') === 'production' ? 0.2 : 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: getConfig('configEnv'),
    enabled: getConfig('env') === 'production',
  });
};
SentryInit(vueApp);

vueApp
  .use(createPinia())
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(websocketPlugin, { reconnectEnabled: true, keys: [WEB_SOCKET_KEY_V5, WEB_SOCKET_KEY] })
  .use(routes)
  .use(PerfectScrollbar)
  .use(Notifications)
  .use(VueClickAway)
  .use(setupCalendar, {})
  .use(initUIKit)
  .use(Vue3Lottie, { name: 'Vue3Lottie' })
  .use(
    createGtm({
      id: getConfig('thirdParty').gtm, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      enabled: getConfig('env') == 'production' || getConfig('configEnv') == 'development', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: routes, // Pass the router instance to automatically sync with router (optional)
    }),
  );

/**
 * Import base component
 */
vueApp.component('DatePicker', DatePicker);
const components = import.meta.glob<true, string, { default: Component }>('./components/atoms/*.vue', {
  eager: true,
});

Object.entries(components).forEach(([filePath, definition]) => {
  // Get name of component, based on filename
  // "./components/Fruits.vue" will become "Fruits"
  const componentName = filePath
    .split('/')
    .pop()
    ?.replace(/\.\w+$/, '');

  if (componentName) {
    // Register component on this Vue instance
    vueApp.component(componentName, definition.default);
  }
});

// Register to dom

// const intervalMS = 60 * 60 * 1000;
// useRegisterSW({
//   onRegistered(r) {
//     r &&
//       setInterval(() => {
//         r.update();
//       }, intervalMS);
//   },
// });

vueApp.config.performance = import.meta.env.PROD;
routes.isReady().then(() => {
  vueApp.mount('#app');
});
