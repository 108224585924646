import { useRoute } from 'vue-router';
import { useInstantAuthentication } from './useInstantAuthentication';
import { useMode } from './useMode';
import { useStoreFrontAuthentication } from './useStoreFrontAuthentication';
import { useV5Authentication } from './useV5Authentication';

export const useShopAuth = () => {
  const route = useRoute();
  const { connectedType, editorConnectedType, updateConnectedType } = useMode();
  useV5Authentication(connectedType, editorConnectedType);
  useInstantAuthentication(connectedType, editorConnectedType);
  useStoreFrontAuthentication(connectedType, editorConnectedType);

  watch(
    () => route.name,
    () => {
      updateConnectedType();
    },
  );
};
