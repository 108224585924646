import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { ShopSelectFragmentDoc } from '../fragments/shop.generated';
import { PublicTokenSelectFragmentDoc } from '../fragments/public-token.generated';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ShopsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['Cursor']>;
  before?: Types.InputMaybe<Types.Scalars['Cursor']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  orderBy?: Types.InputMaybe<Types.ShopOrder>;
  where?: Types.InputMaybe<Types.ShopWhereInput>;
}>;

export type ShopsQueryResponse = {
  shops?: Types.Maybe<
    Pick<Types.ShopConnection, 'totalCount'> & {
      edges?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<Types.ShopEdge, 'cursor'> & {
              node?: Types.Maybe<
                Pick<Types.Shop, 'createdAt' | 'handle' | 'id' | 'deployPlatformDomain' | 'shopName' | 'updatedAt'> & {
                  publicToken: Pick<Types.PublicToken, 'publicShopToken' | 'storefrontHandle' | 'storefrontToken'>;
                }
              >;
            }
          >
        >
      >;
      pageInfo: Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>;
    }
  >;
};

export const ShopsDocument = `
    query shops($after: Cursor, $before: Cursor, $first: Int, $last: Int, $orderBy: ShopOrder, $where: ShopWhereInput) {
  shops(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    where: $where
  ) {
    edges {
      cursor
      node {
        ...ShopSelect
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${ShopSelectFragmentDoc}
${PublicTokenSelectFragmentDoc}`;
export const useShopsQuery = <TData = ShopsQueryResponse, TError = unknown>(
  variables?: ShopsQueryVariables | ComputedRef<ShopsQueryVariables> | Ref<ShopsQueryVariables>,
  options?: UseQueryOptions<ShopsQueryResponse, TError, TData>,
) =>
  useQuery<ShopsQueryResponse, TError, TData>(
    variables === undefined ? ['shops'] : ['shops', variables],
    appFetcher<ShopsQueryResponse, ShopsQueryVariables>(ShopsDocument, variables),
    options,
  );

useShopsQuery.getKey = (
  variables?: ShopsQueryVariables | ComputedRef<ShopsQueryVariables> | Ref<ShopsQueryVariables>,
) => (variables === undefined ? ['shops'] : ['shops', variables]);
