import type { RouteRecordRaw } from 'vue-router';

const instantRoutes: RouteRecordRaw[] = [
  {
    path: 'views',
    name: 'InstantPageViews',
    component: () => import('./pages/PageViews.vue'),
  },
  {
    path: 'pages',
    name: 'InstantPages',
    component: () => import('./pages/Pages.vue'),
  },
  {
    path: 'home-pages',
    name: 'HomePages',
    component: () => import('./pages/HomePages.vue'),
  },
  {
    path: 'landing-pages',
    name: 'LandingPages',
    component: () => import('./pages/LandingPages.vue'),
  },
  {
    path: 'product-pages',
    name: 'ProductPages',
    component: () => import('./pages/ProductPages.vue'),
  },
  {
    path: 'collection-pages',
    name: 'CollectionPages',
    component: () => import('./pages/CollectionPages.vue'),
  },
  {
    path: 'article-pages',
    name: 'ArticlePages',
    component: () => import('./pages/ArticlePages.vue'),
  },
];

export default instantRoutes;
