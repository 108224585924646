import type { RouteRecordRaw } from 'vue-router';

const themeRoutes: RouteRecordRaw[] = [
  {
    path: 'themes',
    component: () => import('../dashboard/layouts/Index.vue'),
    children: [
      {
        path: '',
        name: 'Themes',
        component: () => import('./pages/Themes.vue'),
        children: [],
      },
      {
        path: ':themeId',
        component: () => import('./layouts/Theme.vue'),
        children: [
          {
            path: '',
            name: 'ThemeDetail',
            component: () => import('./pages/Page.vue'),
          },
          {
            path: 'section',
            name: 'ThemeSection',
            component: () => import('./pages/Section.vue'),
          },
          {
            path: 'styles',
            name: 'ThemeGlobalStyles',
            component: () => import('./pages/Style.vue'),
          },
        ],
      },
    ],
  },
];

export default themeRoutes;
