import type * as Types from '@/types/graphql';

export type PublicTokenSelectFragment = Pick<
  Types.PublicToken,
  'publicShopToken' | 'storefrontHandle' | 'storefrontToken'
>;

export const PublicTokenSelectFragmentDoc = `
    fragment PublicTokenSelect on PublicToken {
  publicShopToken
  storefrontHandle
  storefrontToken
}
    `;
