import type * as Types from '@/types/graphql';

import { PublicTokenSelectFragmentDoc } from './public-token.generated';
export type ShopSelectFragment = Pick<
  Types.Shop,
  'createdAt' | 'handle' | 'id' | 'deployPlatformDomain' | 'shopName' | 'updatedAt'
> & { publicToken: Pick<Types.PublicToken, 'publicShopToken' | 'storefrontHandle' | 'storefrontToken'> };

export const ShopSelectFragmentDoc = `
    fragment ShopSelect on Shop {
  createdAt
  handle
  id
  deployPlatformDomain
  shopName
  updatedAt
  publicToken {
    ...PublicTokenSelect
  }
}
    `;
