import { inject } from 'vue';
import { PING_INTERVAL, PONG_INTERVAL, RECONNECT_INTERVAL, WEB_SOCKET_KEY } from './const';
import type { EmitFunc, EmitMessageFunc, WebsocketOptions, WebsocketPlugin, WebsocketStore } from './types';
import { _messageEvents } from './types';
import { _events } from './types';

const websocketPlugin: WebsocketPlugin = {
  install(app, options) {
    // configure the app
    const keys = options.keys ?? [WEB_SOCKET_KEY];
    keys.forEach((key) => {
      const ws = createWebsocket(options);
      app.provide(key, ws);
      app.config.globalProperties[`$ws_${key}`] = ws;
    });
  },
};

const createWebsocket = (options: WebsocketOptions): WebsocketStore => {
  let ws: WebSocket | undefined;
  let forbidReconnect: boolean = false;

  let interval: any;
  let pingTimeoutId: any = null;
  let pongTimeoutId: any = null;

  const connect = (url: string) => {
    if (ws?.OPEN) return;
    if (!url) {
      throw 'WS url undefined';
    }
    forbidReconnect = false; // update remove forbidReconnect

    ws = new WebSocket(url);
    ws.onopen = (evt) => {
      if (interval) {
        clearInterval(interval);
      }

      emit('open', evt);
      heartCheck();
    };
    ws.onerror = (evt) => {
      emit('error', evt);
      reconnect(url);
    };
    ws.onclose = (evt) => {
      emit('close', evt);
      reconnect(url);
    };
    ws.onmessage = (evt) => {
      const data = JSON.parse(evt.data);
      emit('message', evt);
      emitMessage(data?.Type, data);
      heartCheck();
    };
  };

  const heartCheck = () => {
    heartReset();
    if (forbidReconnect) return;
    pingTimeoutId = setTimeout(() => {
      if (!ws) return;
      if (ws.readyState !== 1) return;
      ws.send('heartbeat');
      pongTimeoutId = setTimeout(() => {
        if (!ws) return;
        if (ws.readyState !== 1) return;
        ws.close();
        ws = undefined;
      }, PONG_INTERVAL);
    }, PING_INTERVAL);
  };

  const heartReset = () => {
    if (pingTimeoutId) {
      clearTimeout(pingTimeoutId);
    }
    if (pongTimeoutId) {
      clearTimeout(pongTimeoutId);
    }
  };

  const reconnect = (url: string) => {
    if (interval) {
      clearInterval(interval);
    }
    if (forbidReconnect) {
      return;
    }
    if (options.reconnectEnabled) {
      interval = setInterval(() => {
        ws = undefined;
        connect(url);
      }, options.reconnectInterval ?? RECONNECT_INTERVAL);
    }
  };

  const emit: EmitFunc = (name, data) => {
    if (!_events[name]) {
      return;
    }

    const fireCallbacks = (callback: (args: any) => void) => {
      callback(data);
    };

    _events[name].forEach(fireCallbacks);
  };

  const emitMessage: EmitMessageFunc = (name, data) => {
    if (!_messageEvents[name]) {
      return;
    }
    const fireCallbacks = (callback: (args: any) => void) => {
      if (data.Data) {
        callback(data.Data);
      } else {
        throw new Error('Websocket incorrect format', data);
      }
    };

    _messageEvents[name].forEach(fireCallbacks);
  };

  const disconnect = () => {
    forbidReconnect = true;
    ws?.close();
    ws = undefined;
  };

  return {
    sendMessage(message: string | ArrayBufferLike | Blob | ArrayBufferView) {
      ws?.send(message);
    },
    sendObject(data: Record<string, any>) {
      ws?.send(JSON.stringify(data));
    },
    on(key, cb) {
      _events[key] = _events[key] ?? [];
      _events[key].push(cb);
      return cb;
    },
    removeListener(type, cb) {
      if (!_events[type]) {
        return;
      }
      const filterListeners = (listener: any) => listener !== cb;
      _events[type] = _events[type].filter(filterListeners);
    },
    onMessage(key, cb) {
      _messageEvents[key] = _messageEvents[key] ?? [];
      _messageEvents[key].push(cb);
      return cb;
    },
    removeMessageListener(key, cb) {
      if (!_messageEvents[key]) {
        return;
      }
      const filterListeners = (listener: any) => listener !== cb;
      _messageEvents[key] = _messageEvents[key].filter(filterListeners);
    },
    offMessage(key, cb) {
      if (!_messageEvents[key]) {
        return;
      }
      const filterListeners = (listener: any) => listener !== cb;
      _messageEvents[key] = _messageEvents[key].filter(filterListeners);
    },
    reconnect,
    connect,
    disconnect,
  };
};

export const useWebsocket = (key?: string) => {
  const data = inject<WebsocketStore>(key || WEB_SOCKET_KEY);
  if (!data) {
    throw new Error('Websocket not found');
  }
  return data;
};

export default websocketPlugin;
