import type { RouteRecordRaw } from 'vue-router';

const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('./layouts/Index.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('./pages/Index.vue'),
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: () => import('./pages/Analytics.vue'),
      },
      {
        path: 'plugins',
        name: 'Plugins',
        component: () => import('./pages/Index.vue'),
      },
      {
        path: 'components',
        name: 'Components',
        component: () => import('./pages/Index.vue'),
      },
      {
        path: 'demo',
        name: 'Demo',
        component: () => import('./pages/Demo.vue'),
      },
    ],
  },
];

export default dashboardRoutes;
