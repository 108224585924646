import posthog from 'posthog-js';
import { ref } from 'vue';

export const isFeatureEnabled = (key: string) => {
  return posthog.isFeatureEnabled(key);
};

export const isBootstrapFeatureEnabled = (key: string, featureFlags: { [key: string]: boolean }) => {
  return featureFlags[key];
};

export const featureFlagInit = (
  key: string,
  {
    userId,
    userProperties,
    overrideFeatureFlags,
  }: {
    userId: string;
    userProperties?: Record<string, any>;
    overrideFeatureFlags: Record<string, boolean>;
  },
) => {
  posthog.init(key, {
    api_host: 'https://app.posthog.com',
    autocapture: false,
    loaded: function (posthog) {
      posthog.identify(userId, userProperties);
    },
  });
  posthog.feature_flags.override(overrideFeatureFlags);
};

export const useFeatureFlag = (featureName: string, defaultValue?: boolean) => {
  const isEnabled = ref<boolean>(defaultValue || false);

  if (posthog.__loaded) {
    isEnabled.value = posthog.isFeatureEnabled(featureName);
  } else {
    posthog.onFeatureFlags(() => {
      isEnabled.value = posthog.isFeatureEnabled(featureName);
    });
  }

  return { isEnabled };
};
